<template>
  <div class="detail-package pt-3 pt-md-0">
    <!-- <section
      class="px-3 pt-md-3 pb-0 px-md-5 pt-md-5 pb-md-0 mt-0 mt-md-0 mt-lg-0"
    > -->
    <NavbarOne
      class="card-top d-md-none d-lg-none"
      id="card-top"
      showNavbar
      showCart
      showCartDesktop
      showSearch
    >
    </NavbarOne>
    <!-- </section> -->
    <icon-loading v-if="loading" class="icon-loading mx-auto" />
    <template v-else>
      <page-not-found class="mx-auto mt-3 mt-md-5" v-if="service == null" />

      <v-card class="ma-md-5 card-detail mr-mobile" v-else>
        <custom-section class="section">
          <v-row dense>
            <v-col cols="12" class="pa-1 pa-md-2">
              <v-row>
                <v-col lg="8" cols="12" md="12" sm="12" class="">
                  <div class="overview-detail pa-3 pa-md-5 h-full">
                    <p class="mb-2 t1">
                      {{
                        $i18n.locale == "id"
                          ? service.name
                          : service[`name_${$i18n.locale}`]
                      }}
                    </p>
                    <p class="mb-2 t2">{{ $t("page.lab_test.overview") }}</p>
                    <p class="mb-2 t3">
                      {{ service[`description_${$i18n.locale}`] }}
                    </p>
                    <p class="mb-2 t2">{{ $t("page.lab_test.benefit") }}</p>
                    <p class="mb-2 t3">
                      {{ service[`benefit_${$i18n.locale}`] }}
                    </p>
                    <template v-if="service.preparation">
                      <p class="mb-2 t2">
                        {{ $t("page.lab_test.patient_preparation") }}
                      </p>
                      <p class="mb-2 t3 text-capitalize">
                        {{
                          $i18n.locale == "en"
                            ? service.preparation.toLowerCase()
                            : service[
                                `preparation_${$i18n.locale}`
                              ].toLowerCase()
                        }}
                      </p>
                    </template>
                  </div>
                </v-col>
                <v-col lg="4" cols="12" md="12" sm="12" class="">
                  <div class="card-search pa-3 pa-md-5 mb-3 mb-md-5">
                    <v-row no-gutters>
                      <v-autocomplete
                        v-model="payload.branch"
                        :items="listBranch"
                        item-value="id"
                        item-text="name"
                        class="inp-text err-inp-text inp-elipse"
                        rounded
                        dense
                        prepend-inner-icon="mdi-home-modern"
                        append-icon=""
                        ref="autoBranch"
                      ></v-autocomplete>
                    </v-row>
                  </div>
                  <!-- disable nrc dan bali -->
                  <template
                    v-if="payload.branch == '' || payload.branch == null"
                  >
                    <coming-soon :type="detail" />
                  </template>
                  <template v-else>
                    <!-- <div
                    class="overview-detail pa-3 pa-md-5 mb-3 mb-md-0"
                    v-if="service.prices"
                  >
                    <v-row no-gutters class="mb-4">
                      <v-col cols="4">
                        <icons icon="simple-profile" />
                      </v-col>
                      <v-col cols="8" class="pl-2">
                        <p class="popular-title">
                          {{
                            $i18n.locale == "id"
                              ? service.name
                              : service[`name_${$i18n.locale}`]
                          }}
                        </p>
                        <p class="popular-price" v-if="service.prices">
                          IDR
                          {{
                            service.prices.length !== 0
                              ? formater(service.prices[0].amount)
                              : "0"
                          }}.00
                        </p>
                      </v-col>
                    </v-row>

                    <div v-if="service.prices" class="footer-class">
                      <v-row class="row mb-4" v-if="!checkItem(service)">
                        <v-col class="text-right " cols="12">
                          <v-btn
                            class="button s12"
                            :disabled="!valid"
                            outlined
                            rounded
                            text
                            @click="addCart(service)"
                          >
                            {{ $t("page.lab_test.add_to_cart") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row class="row mb-4" v-if="checkItem(service)">
                        <v-col cols="12">
                          <v-btn
                            class="button remove s12"
                            :disabled="!valid"
                            outlined
                            rounded
                            text
                            @click="removeCart(service)"
                            v-if="checkItem(service)"
                          >
                            {{ $t("page.lab_test.cancel") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </div>
                  </div> -->
                    <div class="overview-detail-card">
                      <card-test
                        :contentImg="true"
                        :active="checkItem(service)"
                        :action="payload.branch != '' && payload.branch != null"
                        :plant="
                          payload.branch != '' && payload.branch != null
                            ? payload.branch
                            : ''
                        "
                        :data="service"
                        :title="
                          $i18n.locale == 'id'
                            ? service.name
                            : service[`name_${$i18n.locale}`]
                        "
                        :sub_title="`IDR ${
                          service.prices != undefined
                            ? service.prices.length !== 0
                              ? formater(service.prices[0].amount)
                              : '0'
                            : '0'
                        }.00`"
                        :desc="service[`description_${$i18n.locale}`]"
                        @addCart="item => addCart(item)"
                        @removeCart="item => removeCart(item)"
                      />
                    </div>
                  </template>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pa-2">
              <div>
                <p class="f-mobile mb-2">
                  {{ $t("page.lab_test.related_test") }}
                </p>
              </div>
              <div class="hr-mobile mb-5">
                <v-divider
                  style="border: 1px solid #00a4b3 !important"
                ></v-divider>
              </div>

              <div class="box-row">
                <v-row>
                  <template v-if="relatedTest.length > 0">
                    <v-col
                      cols="12"
                      md="6"
                      v-for="(test, index) of relatedTest"
                      :key="index"
                      class="px-md-3 px-2 py-2 py-md-3"
                    >
                      <card-test
                        :contentImg="false"
                        :active="checkItem(test)"
                        :action="payload.branch != '' && payload.branch != null"
                        :plant="
                          payload.branch != '' && payload.branch != null
                            ? payload.branch
                            : ''
                        "
                        :data="test"
                        :title="
                          $i18n.locale == 'en'
                            ? test[`name_${$i18n.locale}`]
                            : test[`name`]
                        "
                        :sub_title="`IDR ${
                          test.prices != undefined
                            ? test.prices.length !== 0
                              ? formater(test.prices[0].amount)
                              : '0'
                            : '0'
                        }.00`"
                        :desc="test[`description_${$i18n.locale}`]"
                        @addCart="item => addCart(item)"
                        @removeCart="item => removeCart(item)"
                      />
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col>
                      <p class="no-record">{{ $t("no_record") }}</p>
                    </v-col>
                  </template>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <DialogMenu
            :tampilkan="dialog"
            @toggleDialog="dialog => toggleDialogMenu(dialog)"
          />
          <v-dialog v-model="dialogConfirmation" persistent max-width="290">
            <v-card class="pa-3">
              <div class="d-flex justify-center mb-3">
                <v-icon x-large color="yellow">
                  mdi-information-outline
                </v-icon>
              </div>
              <p class="text-center">
                {{ $t("some_product_not_available_in_this_branch") }}
              </p>
              <div class="d-flex justify-center">
                <c-button class="mx-2 py-2" rounded @click="storeCart()">{{
                  $t("continue")
                }}</c-button>
                <c-button
                  class="mx-2 py-2"
                  rounded
                  outline
                  @click="
                    () => {
                      dialogConfirmation = false;
                      serviceSelected = {};
                    }
                  "
                  >{{ $t("cancel") }}</c-button
                >
              </div>
            </v-card>
          </v-dialog>
        </custom-section>
      </v-card>
    </template>
  </div>
</template>

<script>
import CustomSection from "@/components/v2/section/index.vue";
import API from "@/service/api_service";
import Auth from "@/service/auth_service";
import PageNotFound from "@/components/v3/elements/page_not_found.vue";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import NavbarOne from "@/components/v2/topbar/navbar_one.vue";
import ComingSoon from "@/components/v2/banner/coming_soon.vue";
import CButton from "@/components/v2/button/index.vue";
import CardTest from "@/components/v3/cards/card-test.vue";
import IconLoading from "@/components/elements/IconLoading.vue";

export default {
  components: {
    CustomSection,
    PageNotFound,
    DialogMenu,
    NavbarOne,
    ComingSoon,
    CButton,
    CardTest,
    IconLoading,
  },
  data: () => ({
    loading: false,
    dialog: false,
    serviceSelected: {},
    dialogConfirmation: false,
    service: null,
    relatedTest: [],
    valid: true,
    paramBranchSLug: "",
    showCart: {
      type: Boolean,
      default: false,
    },
    showCartDesktop: {
      type: Boolean,
      default: false,
    },
    dataMyPackage: [],
    mypackage: false,
    servicePrice: { prices: [] },
    branchPar: "",
    payload: {
      branch: "",
    },
    listBranch: [],
    detail: "detail",
  }),
  async created() {
    this.getBranch();
    this.checkBranchActive();
    this.getRelatedProduct();
  },
  watch: {
    "payload.branch": function () {
      history.replaceState(
        null,
        null,
        `?branch=${this.payload.branch == null ? "" : this.payload.branch}`
      );
      this.getProductDetail();
      this.getRelatedProduct();
    },
  },
  methods: {
    async getRelatedProduct() {
      try {
        const respRelated = await API.get(
          `${process.env.VUE_APP_API_TRUST}product-related/get?product_id=${this.$route.params.id}&plant_id=${this.payload.branch}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        this.relatedTest = respRelated.results;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    async getProductDetail() {
      try {
        this.loading = true;
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get/${this.$route.params.id}?plant_id=${this.payload.branch}`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.service = resp.results[0];
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
          });
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log("Error 403!");
      }
    },
    checkBranchActive() {
      if (
        this.$route.query.branch == undefined ||
        this.$route.query.branch == "" ||
        this.$route.query.branch == "default"
      ) {
        // if (this.$store.state.cart.length > 0) {
        //   this.$store.state.cart[0].plant.id;
        // }
        this.payload.branch = "";
      } else {
        this.payload.branch = parseInt(this.$route.query.branch);
      }
      this.getProductDetail();
    },
    getAvailableTest(test) {
      return test.prices.findIndex(
        branch => branch.plant_id == this.payload.branch
      );
    },
    async getBranch() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}plant/get-lab-queue?product_id=${this.$route.params.id}`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode == 200) {
          this.listBranch = resp.results;
        } else {
          this.$swal({
            icon: "error",
            text: resp.message,
          });
        }
      } catch (error) {
        console.log("Error 403!");
      }
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async updateCartApi() {
      await this.$store.dispatch("fetchCartData");
    },
    addCart(data) {
      this.serviceSelected = data;
      if (this.$store.state.cart.length > 0) {
        if (this.$store.state.cart[0].plant.id == this.payload.branch) {
          //this.storeCart();
          const indexCart = this.$store.state.cart.find(
            res => res.deal !== null || res.package !== null
          );
          if (typeof indexCart === "undefined") {
            this.storeCart();
          } else {
            this.$swal({
              text: this.$t("not_have_deal_subs_cart"),
              icon: "warning",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: this.$t("yes"),
              cancelButtonText: this.$t("no"),
              showCancelButton: true,
              showCloseButton: true,
            }).then(result => {
              if (result.isConfirmed) {
                this.$store.state.cart.forEach(el => {
                  if (el.deal != null) {
                    this.removeCartAll(el.package.id, el.deal.id, el.plant.id);
                  } else {
                    this.removeCartAll(el.package.id, null, el.plant.id);
                  }
                });
                this.storeCart();
              }
            });
          }
        } else {
          this.dialogConfirmation = true;
        }
      } else {
        this.storeCart();
      }
    },
    async storeCart() {
      try {
        const dataReady = [
          {
            plant_id: this.payload.branch,
            package_id: null,
            deal_id: null,
            from_package: false,
            product_id: this.serviceSelected.id,
            qty: 1,
          },
        ];

        const resp = await API.post(
          `${process.env.VUE_APP_API_TRUST}cart/store`,
          {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Auth.getToken()}`,
          },
          dataReady
        );
        this.updateCartApi();
        if (resp.statusCode == 200) {
          this.serviceSelected = {};
        } else {
          this.serviceSelected = {};
        }
        this.dialogConfirmation = false;
      } catch (error) {
        console.log("Error 403!");
      }
    },
    removeCartAll(package_id, deal_id, branch) {
      const dataReady = [
        {
          plant_id: branch,
          package_id: package_id,
          deal_id: deal_id,
          from_package: false,
          product_id: null,
          qty: 0,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        // ?lang=${this.$i18n.locale}
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        } else if (res.statusCode == 403) {
          Object.keys(res.errors).forEach(element => {
            this.toast(
              "error",
              typeof res.errors[element] == "object"
                ? res.errors[element][0]
                : res.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: res.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      });
    },
    removeCart(data) {
      const dataReady = [
        {
          plant_id: this.payload.branch,
          package_id: null,
          deal_id: null,
          from_package: false,
          product_id: data.id,
          qty: 0,
        },
      ];
      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        }
      });
    },
    checkItem(item) {
      if (this.$store.state.cart.length > 0) {
        if (this.$route.query.branch === this.payload.branch) {
          this.branchPar = this.$route.query.branch;
        } else {
          this.branchPar = this.payload.branch;
        }

        if (this.branchPar == this.$store.state.cart[0].plant.id) {
          return this.$store.state.cart.findIndex(
            res => res.product != null && res.product.id == item.id
          ) < 0
            ? false
            : true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    async openDialog() {
      this.dialog = true;
    },
    toggleDialogMenu(toggle) {
      this.dialog = toggle;
    },
  },
};
</script>
<style lang="scss">
.banner {
  img {
    width: 60% !important;
  }
}
.inp-elipse
  > .v-input__control
  > .v-input__slot
  > .v-select__slot
  > input[type="text"] {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}
.card-search {
  .v-text-field--rounded > .v-input__control > .v-input__slot {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
@import "../../../../assets/scss/color.scss";
.remove {
  background-color: white !important;
  color: #00a4b3 !important;
  border: thin solid currentColor !important;
  border-color: #00a4b3 !important;
}
.detail-package {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
  overflow-x: hidden;
  min-height: 700px;
  &__section {
    padding: 0 20px;
  }
  .section {
    padding: 20px;
  }
  a {
    text-decoration: none;
  }
  .h-full {
    height: 100%;
  }
}
.overview-detail {
  border: 1px solid #777777;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.overview-detail-card {
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.t1 {
  font-size: 14px !important;
  color: #233f78;
  font-weight: 700;
}
.t2 {
  font-size: 14px !important;
  color: #00a4b3;
  font-weight: 600;
}
.t3 {
  font-size: 12px !important;
  color: #1f3c87;
  font-weight: 300;
}
.popular-title {
  font-weight: 600;
  font-size: 14px;
  color: #1f3c87;
  margin-bottom: 7px;
}
.popular-price {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
  padding: 0 0 10px 0;
  margin-bottom: 10px;
  color: #1f3c87;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}
.no-record {
  text-align: center;
  padding-top: 50px;
}
.card-popular {
  background: #ffffff;
  border: 1px solid #777777;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 12px;
}
.btn-detail {
  background-color: #00a4b3 !important;
  border-radius: 8px;
  font-weight: 300;
  // font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff !important;
  text-transform: capitalize;
}

.footer-class {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: -32px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  color: $main_2 !important;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 64px;
  padding: 0 16px;
}

.button {
  width: 0;
}

.theme--light.v-btn.v-btn--outlined.v-btn--text {
  border-radius: 5px;
  width: 100%;
  background-color: #00a4b3;
  color: white;
  text-transform: capitalize;
}
.text-field {
  max-width: 70%;
  margin-top: -3px;
  margin-right: 4px !important;
  margin-left: 3px;
}
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 97%;
  margin-top: -18px;
  margin-left: 20px;
}

.back {
  cursor: pointer;
}

.column-back {
  margin-right: -50px;
  margin-top: -5px;
  margin-left: -10px;
}
.arrow-back {
  cursor: pointer;
}
.removeBookNow {
  flex: 100% !important;
  max-width: 100% !important;
}
@media only screen and (max-width: 600px) {
  .column-back {
    margin-right: 0px;
    margin-left: -15px;
    margin-top: -5px;
  }

  .back {
    cursor: pointer;
  }

  .text-field {
    max-width: 87% !important;
  }

  .cart-right {
    margin-left: -10px !important;
    margin-top: -19px;
    margin-left: -45px !important;
  }

  .bell-mobile {
    margin-left: 8px;
  }
  .user-card {
    max-width: 62% !important;
    margin-left: 155px;
    margin-right: 10px;
    margin-top: -40px;
  }

  .card-top {
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .notification {
    height: 55px;
    border-radius: 3px;
    margin-top: -15px;
    margin-left: -80px;
  }
  .cart {
    margin-left: 11px;
    z-index: 1;
  }

  .card-detail {
    margin-top: 60px;
  }
}
.s12 {
  font-size: 12px;
}
.card-search {
  background: #00a4b3;
  border-radius: 10px;
  padding: 5px 20px 20px 20px;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}
.inp-text {
  background-color: #ffffff;
  border-radius: 10px;
  height: 40px;
  padding: 4px;
  font-size: 12px !important;
}

.branch-title {
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 4px;
  margin-top: -5px;
}
// 1264
.hr-mobile {
  display: none;
}
@media only screen and (max-width: 1264px) {
  .hr-mobile {
    display: block;
  }
}
.mr-mobile {
  margin-top: 64px;
  margin-right: 12px;
  margin-bottom: 12px;
  margin-left: 12px;
}
@media only screen and (max-width: 767px) {
  .d-btn-show {
    display: none !important;
  }
  .m-btn-show {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .mr-mobile {
    margin: 55px 0 0 0 !important;
    box-shadow: unset !important;
  }
  .w100 {
    width: 100% !important;
  }
  .detail-package {
    background: white;
  }
  .f-mobile {
    color: #1f3c87 !important;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
