<template>
  <div class="banner" v-if="type != 'detail'">
    <img src="@/assets/images/banner/coming_soon.png" height="160" class="" />
    <h4>{{ $t("coming_soon") }} {{type}}</h4>
  </div>
  <div class="banner" v-else>
    <img src="@/assets/images/banner/select_branch.png" height="160" class="" />
    <!-- <h4 style="font-size:18px;">{{ $t("select_branch_above") }} {{type}}</h4> -->
    <h4 style="font-size:18px;">{{ $t("select_branch_above") }}</h4>
  </div>
</template>

<script>
export default {
  props: {
    type: {
        type: String,
        default: "",
      },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.banner {
  text-align: center;
  padding-top: 24px;
  img {
    width: 100%;
    max-width: 500px;
    height: auto;
  }
  h4 {
    color: $main_1;
    margin-top: 12px;
    font-size: 34px;
  }
}
</style>
